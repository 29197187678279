/* eslint-disable require-jsdoc-except/require-jsdoc */
import React from 'react';
import ReactDOM from 'react-dom';
import persistStore from 'redux-persist/lib/persistStore';
import persistReducer from 'redux-persist/lib/persistReducer';

import { createHashHistory, createBrowserHistory } from 'history';
import { ConnectedRouter as ConnectedRouterDefault, connectRouter as connectRouterDefault, routerMiddleware as routerMiddlewareDefault } from 'connected-react-router';
import { ConnectedRouter as ConnectedRouterImmutable, connectRouter as connectRouterImmutable, routerMiddleware as routerMiddlewareImmutable } from 'connected-react-router/immutable';

import { Route, Switch } from 'react-router-dom';

import * as Immutable from 'immutable';

import { applyMiddleware, combineReducers as combineReducersDefault } from 'redux';
import { createInjectSagasStore, sagaMiddleware, injectSaga as injectAsyncSaga } from 'redux-sagas-injector';
import { combineReducers as combineReducersImmutable } from 'redux-immutable';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
// import Pages from 'pages';

import rootSaga from '../sagas/rootSaga';
import reducers, { setAppInfoAction } from '../state';

const APP_DATA = {
  name: '',
  hash: false,
  history: null,
  routerReducer: () => {},
  immutable: true,
  persistSetup: null,
  routes: [],
  routeElements: [],
  reducers: { ...reducers },
  sagas: {},
  store: null,
  persistor: null,
};

const addRoutes = (routes) => {
  APP_DATA.routes = [
    // { path: '/notFound', component: Pages.NotFoundPage },
  ];

  if (routes) {
    APP_DATA.routes = APP_DATA.routes.concat(routes);
  }
};

const addReducers = (externalReducers) => {
  if (externalReducers) {
    // check if reducer with existing key is added
    Object.keys(externalReducers).forEach((key) => {
      if (APP_DATA.reducers[key] !== undefined) {
        throw new Error(`You are trying to add a reducer with key '${key}' which already existis. To avoid losing funcionality, please, change the key name.`);
      }
    });
  }

  APP_DATA.reducers = {
    ...APP_DATA.reducers,
    ...externalReducers,
  };
};

const createReducers = () => (APP_DATA.persistSetup !== null
  ? persistReducer(
    APP_DATA.persistSetup,
    APP_DATA.combineReducers({
      ...APP_DATA.reducers,
    }),
  )
  : APP_DATA.reducers);

export const injectReducer = (key, asyncReducer) => {
  if (!APP_DATA.reducers[key]) {
    APP_DATA.reducers[key] = asyncReducer;
    APP_DATA.store.replaceReducer(createReducers());
    APP_DATA.persistor.persist();
  }
};

export const injectSaga = (key, saga) => {
  injectAsyncSaga(key, saga);
  APP_DATA.sagas[key] = saga;
  APP_DATA.persistor.persist();
};

const injectSagas = (sagas) => {
  if (sagas) {
    Object.keys(sagas).forEach((key) => {
      injectAsyncSaga(key, sagas[key]);
    });
  }
};

const createRoutes = () => {
  APP_DATA.routeElements = APP_DATA.routes.map((route) => (
    /* eslint react/no-children-prop: 0 */
    route.path
      ? (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
          render={route.render}
          children={route.children}
        />
      )
      : route));
};

const createStore = () => {
  createRoutes();
  const routerMiddleware = APP_DATA.immutable ? routerMiddlewareImmutable : routerMiddlewareDefault;
  const routingMiddleware = routerMiddleware(APP_DATA.history);
  const allMiddlewares = [routingMiddleware, sagaMiddleware];

  APP_DATA.reducers = {
    ...APP_DATA.reducers,
    router: !APP_DATA.immutable
      ? (state, action) => {
        const result = APP_DATA.routerReducer({ router: state }, action);
        return result.router;
      }
      : (state, action) => {
        const result = APP_DATA.routerReducer(Immutable.fromJS({ router: state }), action);
        return result.get('router');
      },
  };

  APP_DATA.store = createInjectSagasStore(
    { olafRootSaga: rootSaga },
    createReducers(),
    // TODO  disable DevTool on production
    composeWithDevTools(applyMiddleware(...allMiddlewares)),
    { combineReducers: APP_DATA.combineReducers },
  );

  APP_DATA.persistor = persistStore(APP_DATA.store, () => {
    APP_DATA.store.getState();
  });

  injectSagas(APP_DATA.sagas);
  sagaMiddleware.run(rootSaga);

  APP_DATA.store.dispatch(setAppInfoAction({ appInfo: { appName: APP_DATA.name } }));
};

const renderApplication = () => {
  // document.addEventListener('DOMContentLoaded', () => {
  const ConnectedRouter = APP_DATA.immutable ? ConnectedRouterImmutable : ConnectedRouterDefault;
  ReactDOM.render(
    <Provider store={APP_DATA.store}>
      <ConnectedRouter history={APP_DATA.history}>
        <Switch>
          {APP_DATA.routeElements}
        </Switch>
      </ConnectedRouter>
    </Provider>,
    APP_DATA.rootDomElement,
  );
  // });
};

const init = () => {
  createStore();
  renderApplication();
};

const setupAppData = (data) => {
  if (data.name !== undefined) {
    APP_DATA.name = data.name;
  }

  if (data.getRootDomElement !== undefined) {
    APP_DATA.rootDomElement = data.getRootDomElement();
  }

  if (data.immutable !== undefined) {
    APP_DATA.immutable = data.immutable;
  }

  if (data.hash !== undefined) {
    APP_DATA.hash = data.hash;
  }

  if (data.persistSetup !== undefined) {
    APP_DATA.persistSetup = data.persistSetup;
  }
};

export const createOlafApplication = (data) => {
  setupAppData(data);

  addRoutes(data.routes);
  addReducers(data.reducers);

  APP_DATA.sagas = data.sagas;

  APP_DATA.history = APP_DATA.hash ? createHashHistory() : createBrowserHistory();
  APP_DATA.combineReducers = APP_DATA.immutable ? combineReducersImmutable : combineReducersDefault;

  APP_DATA.routerReducer = APP_DATA.immutable
    ? connectRouterImmutable(APP_DATA.history)(() => Immutable.fromJS({}))
    : connectRouterDefault(APP_DATA.history)(() => ({}));

  init();

  return { store: APP_DATA.store, persistor: APP_DATA.persistor };
};

class Application extends React.Component {
  constructor(props) {
    super(props);

    createOlafApplication(props);
  }
}

export default Application;
