import { all, call } from 'redux-saga/effects';
import { REDUCER_NAME } from '../state/defaultReducer';

import defaultSagas from './defaultSagas';

/**
 * Root saga
 * @async
 * @returns {void}
 */
function* rootSaga() {
  yield all({
    [REDUCER_NAME]: call(defaultSagas),
  });
}

export default rootSaga;
