/**
 * Prefix for every Redux action defined by the Olaf Framework.
 * @constant
 */
const ACTIONS_PREFIX_BASE = '@olaf';

/**
 * Prefix for utility Redux actions.
 * @constant
 */
export const UTILS_ACTIONS_PREFIX = `${ACTIONS_PREFIX_BASE}/utils/`;

/**
 * Constants used to tag component related logs, fetch requests statuses, etc ...
 * @constant
 */
export const TAGS = {
  GLOBAL: 'GLOBAL',
};
