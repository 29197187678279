import { TAGS } from 'config';
import { resolveState } from './utils';
import { REDUCER_NAME } from '../state/defaultReducer';

/**
 * Selector returning status for the specific fetch call.
 * @param {Immutable.Map} state App state.
 * @returns {state}
 */
export const getData = (state) => resolveState(state, REDUCER_NAME).clickNo;

/**
 * Selector returning status for the specific fetch call differentiated by tag.
 * @param {Immutable.Map} state App state.
 * @param {string} tag One of the tags defined in {@link TAGS}
 * @returns {state}
 */
export const getFetchStatus = (state, tag = TAGS.GLOBAL) => (
  resolveState(state, REDUCER_NAME).statuses[tag].fetchStatus);


/**
 * Selector returning error (if any) for the specific fetch call differentiated by tag.
 * @param {Immutable.Map} state App state.
 * @param {string} tag One of the tags defined in {@link TAGS}
 * @returns {state}
 */
export const getFetchError = (state, tag = TAGS.GLOBAL) => (
  resolveState(state, REDUCER_NAME).statuses[tag].fetchError);


/**
 * Selector returning is fetchig in progress for the specific fetch call differentiated by tag.
 * @param {Immutable.Map} state App state.
 * @param {string} tag One of the tags defined in {@link TAGS}
 * @returns {state}
 */
export const getFetchInProgress = (state, tag = TAGS.GLOBAL) => (
  resolveState(state, REDUCER_NAME).statuses[tag].fetchInProgress);

/**
 * Selector returnig app name defined on app initialisation. Check {@link Application}.
 * @param {Immutable.Map} state App state.
 * @returns {state}
 */
export const getAppName = (state) => resolveState(state, REDUCER_NAME).appInfo.appName;
