import { takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_STARTED_ACTION, FETCH_ERROR_ACTION, FETCH_SUCCESS_ACTION } from 'state/defaultReducer';
import { TAGS } from 'config';
import request from './utils';


/**
 * fetch
 * @async
 * @param {object} action
 * @returns {void}
 */
function* init(action) {
  yield call(() => {});
  console.log('initial action is done ', action);
}

/**
 * fetch
 * @async
 * @param {string} url
 * @param {object} options
 * @param {string} tag
 * @returns {void}
 */
export function* fetch(url, options, tag = TAGS.GLOBAL) {
  // console.log('fetch called = ', url, options);

  let response = {};

  yield put({
    type: FETCH_STARTED_ACTION,
    payload: {
      tag,
    },
  });

  try {
    response = yield call(request, url, options);


    yield put({
      type: FETCH_SUCCESS_ACTION,
      payload: {
        tag,
      },
    });
  } catch (error) {
    yield put({
      type: FETCH_ERROR_ACTION,
      payload: {
        tag,
        error: {
          message: error.message,
          url,
        },
      },
    });

    throw error;
  }

  return response;
}

/**
 * Default saga
 * @async
 * @returns {void}
 */
function* defaultSaga() {
  yield takeLatest('INIT_ACTION', init);
}


export default defaultSaga;
